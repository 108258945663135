// Stubbed endpoints
export const getIncidentsByTagQuery = 'getIncidentsByTag';
export const getIncidentPhaseQuery = 'getIncidentPhase';
export const getIncidentFormQuery = 'getIncidentForm';
export const getIncidentCreateFormQuery = 'getIncidentCreateForm';
export const getIncidentDataFromICFQuery = 'getIncidentDataFromICF';
export const getInitialRecordQuery = 'getInitialRecord';
export const searchInitialRecordsQuery = 'searchInitialRecords';
export const submitIncidentFormQuery = 'submitIncidentForm';
export const updateIncidentTagsQuery = 'updateIncidentTags';

export const createInitialReportQuery = `
    mutation CreateInitialReport($input: CreateFroiInput!) {
        createInitialReport: createFroi(input: $input) {
            froiId
            status
            userAlias
            forms {
                id
            }
            scope {
                nodeIds {
                    id
                    isHierarchyNode
                    tenantHierarchyId
                }
            }
        }
    }
`;

export const submitInitialReportQuery = `
    mutation SubmitSimplifiedIRF($input: SubmitFroiInput!) {
        submitFroi(input: $input) {
            id
            caseNumber
            createdAt
            creator
            type
            version
        }
    }
`;

export const createIncidentQuery = `
    mutation CreateIncident($input: CreateIncidentInput!) {
        createIncident(input: $input) {
            id
            caseNumber
        }
    }
`;

const lifeCycleStatusFragment = `lifeCycleCounts {
    ready
    closed
    draft
    forReview
    inProgress
}`;
const medicalStatusFragment = `medicalStatusCounts {
    claimFiled
    closed
    notStarted
    open
    pendingOutsideMedical
    suspended
}`;

export const getIncidentSiteStatsQuery = `query GetIncidentSiteStats($input:GetIncidentSiteStatsInput!) {
    getIncidentSiteStats(input: $input) {
        ${lifeCycleStatusFragment}
        ${medicalStatusFragment}
        siteId
    }
}`;

export const getIncidentSiteStatsRestrictedQuery = `query GetIncidentSiteStats($input:GetIncidentSiteStatsInput!) {
    getIncidentSiteStats(input: $input) {
        ${lifeCycleStatusFragment}
        siteId
    }
}`;

export const getIncidentVersionQuery = `
    query GetIncidentVersion($input: GetIncidentInput! ) {
        getIncident(input: $input) {
            id
            status
            version
        }
    }
`;

export const phaseStageFragment = `
    fragment stageFields on GenericStage {
        name
        type
        deprecated
        formInfo {
            formId
            formVersion
        }
        followUpInfo {
            followUpId
        }
        actionInfo {
            findingId
            incidentFindingType
        }
        rcaInfo {
            findingId
            rcaId
            incidentFindingType
            editable
        }
        incidentReviewInfo {
            approved
            approver
            details
            formsList {
                formId
                formVersion
            }
            submissionDate
        }
        status
        regulatoryReviewInfo {
            currentReview {
                details
                medicalAttachments {
                    id
                    fileName
                    updateType
                    updatedBy
                    updatedDate
                    comment
                }
                reviewType
                submittedBy
                submissionDate
                updateRequired
            }
            completedReviews {
                details
                medicalAttachments {
                    id
                    fileName
                    updateType
                    updatedBy
                    updatedDate
                    comment
                }
                reviewType
                submittedBy
                submissionDate
                updateRequired
            }
        }
        parkInfo {
            currentWorkflow {
                startDateTime
                deadlineDateTime
                justification
                justificationBy
                justificationDate
                reviewNotes
                medicalAttachments {
                    id
                    fileName
                    updateType
                    updatedBy
                    updatedDate
                    comment
                }
                status
                triggers
                workRelatedException
            }
            completedWorkflows {
                startDateTime
                deadlineDateTime
                justification
                justificationBy
                justificationDate
                reviewNotes
                medicalAttachments {
                    id
                    fileName
                    updateType
                    updatedBy
                    updatedDate
                    comment
                }
                status
                triggers
                workRelatedException
             }
        }
        postRiskAssessmentReviewInfo {
            findingId
            submissionDate
        }
    }
`;

const incidentFragment = `
    isPrimaryIncident
    phases {
        name
        type
        dueDate
        section
        locks
        status
        isMandatory
        canLockQuestions
        isLocked
        questionIdsToLock
        allowedAttachmentTypes
        helpPanels {
            panelId
            placement
        }
        attachments {
            id
            name
            type
            createdAt
            uploadedBy
            description
        }
        featuresEnabled
        id
        assignee {
            assigneeType
            assigneeGroupName
            id
        }
        stages {
            ...stageFields
        }
    }
    caseNumber
    createdAt
    creator
    customSearchAttributes {
        key
        value
    }
    id
    incidentDateTime
    incidentReportedDateTime
    incidentGroupId
    name
    recordable
    reportable
    riskAssessments {
        likeliness
        riskCriticality
        severity
        type
    }
    riskCategory {
        category
        group
        hazard
    }
    createdScope: scope {
        nodeIds {
            id
        }
    }
    severity {
        editReason
        level
    }
    scope: dynamicScope {
        nodeIds {
            id
            isHierarchyNode
            tenantHierarchyId
        }
    }
    potentialSeverity {
        editReason
        level
    }
    status
    type
    updatedAt
    version
    audience {
        audienceType
        id
    }
`;

export const getIncidentPhasesQuery = `
    query GetIncidentPhases($input: GetIncidentInput! ) {
        getIncident(input: $input) {
            phases {
                name
                type
                dueDate
                section
                locks
                status
                featuresEnabled
                assignee {
                    assigneeType
                    id
                }
                stages {
                    ...stageFields
                }
            }
            version
        }
    }
    ${phaseStageFragment}
`;

export const getIncidentQuery = `
    query GetIncident($input: GetIncidentInput! ) {
        getIncident(input: $input) {
            ${incidentFragment}
        }
    }
    ${phaseStageFragment}
`;

export const getInitialReportQuery = `
    query GetFROI($input: GetFroiInput!) {
        getInitialReport: getFroi(input: $input) {
            froiId
            status
            creator: userAlias
            employeeAttributes {
                attributeKey
                attributeValue
            }
            forms {
                id
                formExternalId
            }
            scope {
                nodeIds {
                    id
                }
            }
        }
    }
`;

export const searchIncidentsQuery = `
    query searchIncidents(
        $pageInfo: PageInfoInput!,
        $queryFilters: [QueryFilterInput!]
        $sortInfos: [SortInfoInput!],
        $compose: [ComposeInput!],
        $locale: String,
        $totalCountFilters: [QueryFilterInput!]
    ) {
        searchIncidents(
        input: {
            pageInfo: $pageInfo,
            queryFilters: $queryFilters,
            sortInfos: $sortInfos,
            compose: $compose,
            locale: $locale,
        }) {
            count
            entities {
                associatedPersonData {
                    alias
                    managerAlias
                }
                createdAt
                updatedAt
                id
                incidentDateTime
                incidentReportedDateTime
                injuredPersonData {
                    alias
                    managerAlias
                    shiftPattern
                }
                name
                creator
                caseNumber
                customSearchAttributes {
                    key
                    value
                }
                incidentGroupId
                isPrimaryIncident
                phases {
                    assignee {
                        assigneeType
                        assigneeGroupName
                        id
                    }
                    dueDate
                    status
                    type
                    isMandatory
                }
                riskAssessments {
                    likeliness
                    riskCriticality
                    severity
                    type
                }
                recordable
                reportable
                scope: dynamicScope {
                    nodeIds {
                        id
                    }
                }
                createdScope: scope {
                    nodeIds {
                        id
                    }
                }
                status
                severity {
                    editReason
                    level
                }
                potentialSeverity {
                    editReason
                    level
                }
                type
            }
            pageInfo {
                from
            }
        },
        totalCount: searchIncidents(
            input: {
                pageInfo: {
                    from: 0
                    size: 0
                },
                queryFilters: $totalCountFilters
            }
          ) {
            count
          }
    }
`;

// @todo this is the same as search incidents with a smaller set of returned fields
// Need to update our graphql client to allow dynamic request fields from consumer instead of in the query definition
export const searchIncidentsWithRestrictedFieldsQuery = `
    query searchIncidentsWithRestrictedFields(
        $pageInfo: PageInfoInput!,
        $queryFilters: [QueryFilterInput!]
        $sortInfos: [SortInfoInput!],
        $compose: [ComposeInput!],
        $locale: String,
        $totalCountFilters: [QueryFilterInput!]
    ) {
        searchIncidents(
        input: {
            pageInfo: $pageInfo,
            queryFilters: $queryFilters,
            sortInfos: $sortInfos,
            compose: $compose,
            locale: $locale,
        }) {
            count
            entities {
                createdAt
                updatedAt
                id
                incidentDateTime
                incidentReportedDateTime
                name
                caseNumber
                incidentGroupId
                scope: dynamicScope {
                    nodeIds {
                        id
                    }
                }
                createdScope: scope {
                    nodeIds {
                        id
                    }
                }
                status
                type
            }
            pageInfo {
                from
            }
        },
        totalCount: searchIncidents(
            input: {
                pageInfo: {
                    from: 0
                    size: 0
                },
                queryFilters: $totalCountFilters
            }
          ) {
            count
          }
    }
`;

export const searchIncidentsV2Query = `
    query searchIncidentsV2(
        $filter: AWSJSON!,
        $pageInfo: PageInfoInput!,
        $sortInfos: [SortInfoInput!],
        $locale: String,
        $totalCountFilters: [QueryFilterInput!]
    ) {
        searchIncidentsV2(
        input: {
            pageInfo: $pageInfo,
            sortInfos: $sortInfos,
            locale: $locale,
            filter: $filter
        }) {
            count
            entities {
                associatedPersonData {
                    alias
                    managerAlias
                }
                createdAt
                updatedAt
                id
                incidentDateTime
                incidentReportedDateTime
                injuredPersonData {
                    alias
                    managerAlias
                    shiftPattern
                }
                name
                creator
                caseNumber
                customSearchAttributes {
                    key
                    value
                }
                incidentGroupId
                isPrimaryIncident
                phases {
                    assignee {
                        assigneeType
                        assigneeGroupName
                        id
                    }
                    dueDate
                    status
                    type
                    isMandatory
                }
                riskAssessments {
                    likeliness
                    riskCriticality
                    severity
                    type
                }
                recordable
                reportable
                scope: dynamicScope {
                    nodeIds {
                        id
                    }
                }
                createdScope: scope {
                    nodeIds {
                        id
                    }
                }
                status
                severity {
                    editReason
                    level
                }
                potentialSeverity {
                    editReason
                    level
                }
                type
            }
            pageInfo {
                from
            }
        },
        totalCount: searchIncidents(
            input: {
                pageInfo: {
                    from: 0
                    size: 0
                },
                queryFilters: $totalCountFilters
            }
          ) {
            count
          }
    }
`;

export const searchIncidentsV2WithRestrictedFieldsQuery = `
    query searchIncidentsV2WithRestrictedFields(
        $pageInfo: PageInfoInput!,
        $queryFilters: [QueryFilterInput!]
        $sortInfos: [SortInfoInput!],
        $compose: [ComposeInput!],
        $locale: String,
        $totalCountFilters: [QueryFilterInput!]
    ) {
        searchIncidentsV2(
        input: {
            pageInfo: $pageInfo,
            queryFilters: $queryFilters,
            sortInfos: $sortInfos,
            compose: $compose,
            locale: $locale,
        }) {
            count
            entities {
                createdAt
                updatedAt
                id
                incidentDateTime
                incidentReportedDateTime
                name
                caseNumber
                incidentGroupId
                scope: dynamicScope {
                    nodeIds {
                        id
                    }
                }
                createdScope: scope {
                    nodeIds {
                        id
                    }
                }
                status
                type
            }
            pageInfo {
                from
            }
        },
        totalCount: searchIncidents(
            input: {
                pageInfo: {
                    from: 0
                    size: 0
                },
                queryFilters: $totalCountFilters
            }
          ) {
            count
          }
    }
`;

export const generateIncidentMedicalRecordQuery = `
    mutation generateMedicalRecords(
        $incidentId: String!
    ) {
        generateMedicalRecords(
        input: {
            incidentId: $incidentId
        }) {
            incidentId
            injuredPersonAlias
            reportInfo {
                irfCount
                initialEncounterCount
                followUpCount
                iCareCount
                medicalAttachmentCount
            }
            downloadableUrl
        }
    }
`;

export const searchIncidentByCaseNumberQuery = `
    query searchIncidentsByCaseNumber(
        $queryFilters: [QueryFilterInput!]
        $pageInfo: PageInfoInput!,
        $sortInfos: [SortInfoInput!],
        $locale: String
    ) {
        searchIncidents(
        input: {
            queryFilters: $queryFilters,
            pageInfo: $pageInfo,
            sortInfos: $sortInfos,
            locale: $locale
        }) {
            entities {
                id
                caseNumber
                type
                customSearchAttributes {
                    key
                    value
                }
            }
        }
    }
`;

export const updateIncidentSeverityQuery = `
    mutation UpdateIncidentSeverity($input: UpdateIncidentSeverityInput!) {
        updateIncidentSeverity(
            input: $input
        ) {
            severity {
                editReason
                level
            }
            version
        }
    }
`;

export const updatePotentialSeverityQuery = `
    mutation UpdatePotentialSeverity($input: UpdatePotentialSeverityInput!) {
        updatePotentialSeverity(
            input: $input
        ) {
            potentialSeverity {
                editReason
                level
            }
            version
        }
    }
`;

export const updateIncidentInitialLoadFlagQuery = `
    mutation UpdateInitialLoad($input: UpdateInitialLoadRequestInput!) {
        updateInitialLoad(input: $input) {
            id
        }
    }
`;

export const updatePhaseAssigneeQuery = `
    mutation UpdatePhaseAssignee($input: UpdatePhaseAssigneeRequestInput!) {
        updatePhaseAssignee(
            input: $input
        ) {
            version
        }
    }
`;

export const getIncidentSummaryQuery = `
    query GetIncidentSummary($input: GetIncidentSummaryInput!) {
        getIncidentSummary(input: $input) {
            id
            version
            summaryPhases {
                name
                source
                summaryQuestions {
                    formId
                    label
                    ordinal
                    questionExternalIds
                    questionIds
                    renderType
                    value
                }
                summaryFields {
                    incidentFieldSummaryType
                    label
                    ordinal
                    value
                }
            }
        }
    }
`;

export const getGroupedIncidentSummariesQuery = `
    query GetGroupedIncidentSummaries($input: GetGroupedIncidentSummariesRequestInput!) {
        getGroupedIncidentSummaries(input: $input) {
            incidentSummaries {
                id
                incidentId
                version
                summaryPhases {
                    name
                    source
                    summaryQuestions {
                        formId
                        label
                        ordinal
                        questionExternalIds
                        questionIds
                        renderType
                        value
                    }
                    summaryFields {
                        incidentFieldSummaryType
                        label
                        ordinal
                        value
                    }
                }
            }
            unauthorizedIncidentCases
        }
    }
`;

export const linkIncidentsQuery = `
    mutation LinkIncidentsToGroup($input: LinkIncidentsToGroupRequestInput!) {
        linkIncidentsToGroup(input: $input) {
            groupId
            linkedIncidents
            primaryIncidentId
            primaryRcaId
            version
        }
    }
`;

export const setPrimaryIncidentQuery = `
    mutation SetPrimaryIncidentForGroup($input: SetPrimaryIncidentForGroupRequestInput!) {
        setPrimaryIncidentForGroup(input: $input) {
            groupId
            linkedIncidents
            primaryIncidentId
            primaryRcaId
            version
        }
    }
`;

export const unlinkIncidentQuery = `
    mutation UnlinkIncidentFromGroup($input: UnlinkIncidentFromGroupRequestInput!) {
        unlinkIncidentFromGroup(input: $input) {
            groupId
            linkedIncidents
            primaryIncidentId
            primaryRcaId
            version
        }
    }
`;

export const getIncidentGroupQuery = `
    query GetIncidentGroup($input: GetIncidentGroupRequestInput!) {
        getIncidentGroup(input: $input) {
            groupId
            linkedIncidents
            primaryIncidentId
            primaryRcaId
            version
        }
    }
`;

export const reopenPhaseQuery = `
    mutation ReopenPhase($input: ReopenPhaseInput!) {
        reopenPhase(input: $input) {
            phases {
                type
                status
                featuresEnabled
                stages {
                    ...stageFields
                }
            }
            version
            status
        }
    }
    ${phaseStageFragment}
`;

export const editFollowUpAssessmentQuery = `
    mutation EditFollowUpAssessment($input: EditFollowUpAssessmentRequestInput!) {
        editFollowUpAssessment(input: $input) {
            formId
        }
    }
`;

export const updateIncidentAudienceQuery = `
    mutation UpdateIncidentAudience($input: UpdateAudienceRequestInput!) {
        updateAudience(
            input: $input
        ) {
            audience {
                audienceType
                id
            }
            version
        }
    }
`;

const employeePersonDataFragment = `
    fragment personDataFields on PersonData {
        agencyName
        amazonAlias
        dateHired
        email
        employeeId
        firstName
        gender
        jobTitle
        lastName
        managerFirstName
        managerLastName
        managerPersonId
        personId
        timestamp
        workLocation
        workerType
        version
        personalDetailsHidden
    }
`;

const personDataFragment = `
    fragment personDataFields on PersonData {
        addressCity
        addressState
        addressStreet
        addressZip
        agencyName
        amazonAlias
        dateHired
        dateOfBirth
        email
        employeeId
        firstName
        gender
        jobTitle
        lastName
        managerFirstName
        managerLastName
        managerPersonId
        personId
        timestamp
        workLocation
        workerType
        version
        personalDetailsHidden
    }
`;

export const getEmployeePersonDataQuery = `
    query GetEmployeePersonData($input: GetPersonDataRequestInput!) {
        getPersonData(input: $input) {
            ...personDataFields
        }
    }
    ${employeePersonDataFragment}
`;

export const getPersonDataQuery = `
    query GetPersonData($input: GetPersonDataRequestInput!) {
        getPersonData(input: $input) {
            ...personDataFields
        }
    }
    ${personDataFragment}
`;

export const savePersonDataMutation = `
    mutation SavePersonData($input: SavePersonDataRequestInput!) {
        savePersonData(input: $input) {
            ...personDataFields
        }
    }
    ${personDataFragment}
`;

export const createPersonDataMutation = `
    mutation CreatePersonData($input: CreatePersonDataRequestInput!) {
        createPersonData(input: $input) {
            ...personDataFields
        }
    }
    ${personDataFragment}
`;

export const createOsha301FormMutation = `
    mutation CreateOsha301Form($input: CreateOsha301FormRequestInput!) {
        createOsha301Form(input: $input) {
            downloadableLink
        }
    }
`;

export const updatePhaseLockQuery = `
    mutation UpdatePhaseLock($input: UpdatePhaseLockRequestInput!) {
        updatePhaseLock(input: $input) {
            ${incidentFragment}
        }
    }
    ${phaseStageFragment}
`;

export const updateIncidentReviewMutation = `mutation updateIncidentReview($request: UpdateIncidentReviewRequestInput!) {
    updateIncidentReview(request: $request) {
        result {
                customReviewAttributes {
                    key
                    value
                }
                details
                reviewType
            }
        }
    }
`;

export const updateIncidentFieldsQuery = `mutation updateIncidentFields($input: UpdateIncidentFieldsRequestInput!) {
    updateIncidentFields(input: $input) {
        id
    }
}
`;

export const migrateAttachmentQuery = `mutation migrateAttachment($request: MigrateAttachmentRequestInput!) {
    migrateAttachment(request: $request) {
        incident {
            phases {
                name
                type
                dueDate
                section
                locks
                status
                isMandatory
                canLockQuestions
                isLocked
                questionIdsToLock
                allowedAttachmentTypes
                attachments {
                    id
                    name
                    type
                    createdAt
                    uploadedBy
                    description
                }
                id
                featuresEnabled
                assignee {
                    assigneeType
                    assigneeGroupName
                    id
                }
                stages {
                    ...stageFields
                }
            }
            caseNumber
            createdAt
            creator
            customSearchAttributes {
                key
                value
            }
            id
            incidentDateTime
            incidentGroupId
            name
            recordable
            reportable
            riskAssessments {
                likeliness
                riskCriticality
                severity
                type
            }
            riskCategory {
                category
                group
                hazard
            }
            createdScope: scope {
                nodeIds {
                    id
                }
            }
            severity {
                editReason
                level
            }
            scope: dynamicScope {
                nodeIds {
                    id
                    isHierarchyNode
                    tenantHierarchyId
                }
            }
            potentialSeverity {
                editReason
                level
            }
            status
            type
            updatedAt
            version
            audience {
                audienceType
                id
            }
        }
    }
}
${phaseStageFragment}
`;

export const getEnabledFeatureFlagsQuery = `query getEnabledFeatureFlags($input: GetIncidentsEnabledFeatureFlagsRequestInput!) {
    getEnabledFeatureFlags(input: $input)
}
`;

export const createGroupedIncidentsQuery = `mutation createGroupedIncidents($input: CreateGroupedIncidentsRequestInput!){
    createGroupedIncidents(input: $input) {
        incidents {
            ${incidentFragment}
        }
        group {
            groupId
        }
    }
}
 ${phaseStageFragment}
`;

export const getGroupedIncidentsQuery = `query getGroupedIncidents($input: GetGroupedIncidentsRequestInput!) {
    getGroupedIncidents(input: $input) {
        incidents {
            ${incidentFragment}
        }
    }
}
 ${phaseStageFragment}
`;

export const deleteAllIncidentsFromGroupQuery = `
    mutation deleteAllIncidentsFromGroup($input: DeleteAllIncidentsFromGroupRequestInput!) {
        deleteAllIncidentsFromGroup(input: $input) {
            failedIncidentCases
        }
    }
`;

export const deleteGroupedIncidentQuery = `
    mutation deleteGroupedIncident($input: DeleteGroupedIncidentRequestInput!) {
        deleteGroupedIncident(input: $input) {
            incidents {
                ${incidentFragment}
            }
        }
    }
${phaseStageFragment}
`;

export const createSecondaryIncidentQuery = `
    mutation createSecondaryIncident($input: CreateSecondaryIncidentRequestInput!) {
        createSecondaryIncident(input: $input) {
             incidents {
                ${incidentFragment}
            }
        }
    }
${phaseStageFragment}
`;

export const bulkCreateSecondaryIncidentQuery = `
    mutation bulkCreateSecondaryIncidents($input: BulkCreateSecondaryIncidentsRequestInput!) {
        bulkCreateSecondaryIncidents(input: $input) {
             incidents {
                ${incidentFragment}
            }
        }
    }
${phaseStageFragment}
`;

export const submitPARkJustificationQuery = `
    mutation submitPARkJustification ($input: SubmitParkJustificationRequestInput!) {
        submitParkJustification(input: $input) {
            ${incidentFragment}
        }
    }
${phaseStageFragment}
`;

export const submitPARkReviewQuery = `
    mutation submitPARkReview ($input: SubmitParkReviewRequestInput!) {
        submitParkReview(input: $input) {
            ${incidentFragment}
        }
    }
${phaseStageFragment}
`;

export const createGroupedIncidentsWithDedupeQuery = `
    mutation createGroupedIncidentsWithDedupe($input: CreateGroupedIncidentsWithDedupeRequestInput!){
        createGroupedIncidentsWithDedupe(input: $input) {
            incidents {
                ${incidentFragment}
            }
            group {
                groupId
            }
            duplicateIncidents {
                caseNumber
                incidentId
                createdBy
                updatedAt
                groupId
                isPrimaryIncident
            }
        }
    }
 ${phaseStageFragment}
`;
