var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
var _t, _u;
/* istanbul ignore file */
import { get } from 'lodash';
import { ISSUE_CRITICALITY_KEY_MAP, RISK_CRITICALITY } from '@amzn/austin-module-actions';
import { STATUS_INDICATOR } from '@amzn/austin-core';
import { RISK_ASSESSMENT_CONFIG } from '../actions/legacy-actions/modules/risk-assessment';
import { FILTER_FIELDS } from '../services/types';
// Pub/Sub event types
export var EVENTS = {
    INCIDENT_ASSESSMENT_UPDATED: 'INCIDENT_FOLLOWUPUPDATED',
    INCIDENT_CREATED: 'INCIDENT_CREATED',
    INCIDENT_SUMMARY_UPDATED: 'INCIDENT_SUMMARYUPDATED',
    INCIDENT_UPDATED: 'INCIDENT_UPDATED',
    INCIDENT_GROUP_UPDATED: 'INCIDENT_GROUPUPDATED',
    FORM_SUBMITTED: 'incidents.form.submitted',
    PHASE_SAVED: 'incidents.phase.saved',
    PHASE_COMPLETED: 'incidents.phase.completed',
    PHASE_UPDATED: 'INCIDENT_PHASEUPDATED',
    FORM_UPDATED: 'FORM_UPDATED',
    SHOW_BANNER: 'incidents.showBanner',
    HIDE_BANNER: 'incidents.hideBanner',
    QUESTION_FILE_ADDED: 'questionFile.added',
    QUESTION_FILE_DELETED: 'questionFile.deleted',
    QUESTION_FILE_PENDING: 'questionFile.pending',
    // Grouped incident events
    GROUPED_INCIDENT_PHASE_UPDATED: 'GROUPED_INCIDENT_PHASEUPDATED',
    GROUPED_INCIDENT_REOPENED: 'GROUPED_INCIDENT_REOPENED',
    GROUPED_INCIDENT_UPDATED: 'GROUPED_INCIDENT_UPDATED',
    GROUPED_INCIDENT_SUMMARY_UPDATED: 'GROUPED_INCIDENT_SUMMARYUPDATED'
};
export var ASSESSMENT_TYPE = {
    FOLLOWUP_ENCOUNTER: 'FOLLOWUP_ENCOUNTER',
    ICARE_ASSESSMENT: 'ICARE_ASSESSMENT',
    INITIAL_ENCOUNTER: 'INITIAL_ENCOUNTER'
};
export var ASSESSMENT_TYPE_LABELS = (_a = {},
    _a[ASSESSMENT_TYPE.FOLLOWUP_ENCOUNTER] = 'ehs_incidents_label_followupEncounter',
    _a[ASSESSMENT_TYPE.ICARE_ASSESSMENT] = 'ehs_incidents_label_icare',
    _a[ASSESSMENT_TYPE.INITIAL_ENCOUNTER] = 'ehs_incidents_label_initialEncounter',
    _a);
export var RCA_FORM_TYPE = {
    UPLOAD: 'UPLOAD',
    ROOT_CAUSE: 'ROOT_CAUSE',
    COMMENTS: 'COMMENTS'
};
export var PHASE_TYPE = {
    APPROVAL: 'APPROVAL',
    ACTIONS: 'ACTIONS',
    CONNECTED_INCIDENTS: 'CONNECTED_INCIDENTS',
    FOLLOWUP: 'FOLLOWUP',
    ICARE: 'ICARE',
    INITIAL_ENCOUNTER: 'INITIAL_ENCOUNTER',
    INVESTIGATION: 'INVESTIGATION',
    RCA: 'RCA',
    RECORD: 'RECORD',
    REGULATORY: 'REGULATORY',
    GARI: 'GARI',
    REVIEW: 'REVIEW',
    SUMMARY: 'SUMMARY',
    IRF: 'IRF',
    MEDICAL_ATTACHMENTS: 'MEDICAL_ATTACHMENTS',
    // Multi-person incident
    MANAGE_INCIDENT: 'MANAGE_INCIDENT'
};
export var PARK_STATUS = {
    IN_REVIEW: 'IN_REVIEW',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    PAST_DUE: 'PAST_DUE',
    CANCELED: 'CANCELED'
};
export var PARK_STATUS_LANG_ID = (_b = {},
    _b[PARK_STATUS.IN_REVIEW] = 'ehs_incidents_label_in_review',
    _b[PARK_STATUS.PENDING] = 'ehs_incidents_label_in_progress',
    _b[PARK_STATUS.APPROVED] = 'ehs_incidents_label_approved',
    _b[PARK_STATUS.DENIED] = 'ehs_incidents_label_denied',
    _b[PARK_STATUS.PAST_DUE] = 'ehs_incidents_label_past_due',
    _b[PARK_STATUS.CANCELED] = 'ehs_incidents_label_cancelled',
    _b);
// @todo Approval phase will be replaced by Reviews phase
// Changing the string for Approval for UX testing, until the Review phase is available
export var PHASE_TYPE_LANG_ID = (_c = {},
    _c[PHASE_TYPE.RECORD] = 'ehs_incidents_label_phaseRecord',
    _c[PHASE_TYPE.INVESTIGATION] = 'ehs_incidents_label_phaseInvestigation',
    _c[PHASE_TYPE.REGULATORY] = 'ehs_incidents_label_phaseRegulatory',
    _c[PHASE_TYPE.GARI] = 'ehs_incidents_label_phaseGari',
    _c[PHASE_TYPE.ACTIONS] = 'ehs_incidents_label_phaseActions',
    _c[PHASE_TYPE.INITIAL_ENCOUNTER] = 'ehs_incidents_label_phaseInitialEncounter',
    _c[PHASE_TYPE.FOLLOWUP] = 'ehs_incidents_label_phaseFollowUp',
    _c[PHASE_TYPE.ICARE] = 'ehs_incidents_label_phaseICare',
    _c[PHASE_TYPE.RCA] = 'ehs_incidents_label_phaseRca',
    _c[PHASE_TYPE.REVIEW] = 'ehs_incidents_label_reviews',
    _c[PHASE_TYPE.SUMMARY] = 'ehs_incidents_label_phaseSummary',
    _c[PHASE_TYPE.CONNECTED_INCIDENTS] = 'ehs_incidents_label_phaseConnectedIncidents',
    _c[PHASE_TYPE.IRF] = 'ehs_incidents_label_phaseIrf',
    _c[PHASE_TYPE.MEDICAL_ATTACHMENTS] = 'ehs_incidents_label_phaseMedicalAttachments',
    _c[PHASE_TYPE.MANAGE_INCIDENT] = 'ehs_incidents_label_phaseManageIncident',
    _c);
export var PHASE_SECTION_TYPE = {
    SUMMARY: 'SUMMARY',
    RECORDING: 'RECORDING',
    MEDICAL: 'MEDICAL'
};
export var PHASE_SECTION_TYPE_LANG_ID = (_d = {},
    _d[PHASE_SECTION_TYPE.RECORDING] = 'ehs_incidents_label_phaseSectionRecording',
    _d[PHASE_SECTION_TYPE.MEDICAL] = 'ehs_incidents_label_phaseSectionMedical',
    _d);
export var INCIDENT_MEDICAL_DISPOSITION = {
    CASE_CLOSED: 'case-closed',
    FOLLOWUP_SCHEDULED: 'aa-continuing-care',
    REFERRED_TO: 'referred-to'
};
export var INCIDENT_MEDICAL_STATUS = {
    NOT_STARTED: 'not-started',
    CLAIM_FILED: 'claim-filed',
    CLOSED: 'closed',
    OPEN: 'open',
    PENDING_OUTSIDE_MEDICAL: 'pending-outside-medical',
    SUSPENDED: 'suspended'
};
export var INCIDENT_CASE_CONDITION = {
    RECORDABLE: 'recordable',
    WORK_RELATED: 'work-related',
    ALL_DAFW: 'all-dafw',
    PENDING_DAFW_END_DATE: 'pending-dafw-end-date',
    ALL_RWA: 'all-rwa',
    PENDING_RWA_END_DATE: 'pending-rwa-end-date',
    AMCARE_BYPASS: 'amcare-bypass'
};
export var INCIDENT_STATUS = {
    APPROVED: 'APPROVED',
    ARCHIVED: 'ARCHIVED',
    READY: 'READY',
    DELETED: 'DELETED',
    DRAFT: 'DRAFT',
    CANCELLED: 'CANCELLED',
    IN_PROGRESS: 'IN_PROGRESS',
    FOR_REVIEW: 'FOR_REVIEW',
    CLOSED: 'CLOSED',
    PROCESSING: 'PROCESSING'
};
export var PHASE_STATUS = {
    DRAFT: 'DRAFT',
    READY: 'READY',
    IN_PROGRESS: 'IN_PROGRESS',
    IN_REVIEW: 'IN_REVIEW',
    COMPLETED: 'COMPLETED',
    CONNECTED: 'CONNECTED',
    NOT_CONNECTED: 'NOT_CONNECTED'
};
export var FORM_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    REVIEW: 'REVIEW',
    ACTIVE: 'ACTIVE'
};
export var FILTER_NAME_LANG_IDS = {
    caseConditions: 'ehs_incidents_label_caseCondition',
    creator: 'ehs_incidents_label_createdBy',
    createdBeforeRelative: 'ehs_incidents_label_createdDateRange',
    createdAfter: 'ehs_incidents_label_createdDateAfter',
    createdBefore: 'ehs_incidents_label_createdDateBefore',
    dateAfter: 'ehs_incidents_label_incidentDateAfter',
    dateBefore: 'ehs_incidents_label_incidentDateBefore',
    dateBeforeRelative: 'ehs_incidents_label_incidentDateRange',
    employeeType: 'ehs_incidents_label_typeOfPersonInvolved',
    eventType: 'ehs_incidents_label_event',
    externalService: 'ehs_incidents_label_imported',
    location: 'ehs_common_label_location',
    medicalStatus: 'ehs_incidents_label_medicalStatus',
    potentialSeverity: 'ehs_incidents_label_potentialSeverity',
    principalBodyPart: 'ehs_incidents_label_principalBodyPart',
    search: 'ehs_common_label_search',
    searchBy: 'ehs_incidents_label_searchBy',
    severity: 'ehs_incidents_label_severity',
    status: 'ehs_incidents_label_incidentStatus',
    type: 'ehs_incidents_label_incidentType'
};
export var INCIDENT_STATUS_LANG_ID = (_e = {},
    _e[INCIDENT_STATUS.APPROVED] = 'ehs_incidents_label_incidentStatus_approved',
    _e[INCIDENT_STATUS.ARCHIVED] = 'ehs_incidents_label_incidentStatus_archived',
    _e[INCIDENT_STATUS.READY] = 'ehs_incidents_label_incidentStatus_ready',
    _e[INCIDENT_STATUS.DELETED] = 'ehs_incidents_label_incidentStatus_deleted',
    _e[INCIDENT_STATUS.DRAFT] = 'ehs_incidents_label_statusDraft',
    _e[INCIDENT_STATUS.CANCELLED] = 'ehs_incidents_label_incidentStatus_cancelled',
    _e[INCIDENT_STATUS.IN_PROGRESS] = 'ehs_incidents_label_incidentStatus_inProgress',
    _e[INCIDENT_STATUS.FOR_REVIEW] = 'ehs_incidents_label_statusForReview',
    _e[INCIDENT_STATUS.CLOSED] = 'ehs_incidents_label_incidentStatus_closed',
    _e[INCIDENT_STATUS.PROCESSING] = 'ehs_incidents_label_incidentStatus_processing',
    _e);
export var ATTACHMENT_STATUS = {
    DELETED: 'ATTACHMENT_DELETED',
    ADDED: 'ATTACHMENT_ADDED'
};
export var ATTACHMENT_STATUS_LANG_ID = (_f = {},
    _f[ATTACHMENT_STATUS.DELETED] = 'ehs_incidents_label_attachmentStatus_deleted',
    _f[ATTACHMENT_STATUS.ADDED] = 'ehs_incidents_label_attachmentStatus_added',
    _f);
export var INCIDENT_MEDICAL_DISPOSITION_LANG_ID = (_g = {},
    _g[INCIDENT_MEDICAL_DISPOSITION.CASE_CLOSED] = 'ehs_incidents_label_medicalDisposition_caseClosed',
    _g[INCIDENT_MEDICAL_DISPOSITION.FOLLOWUP_SCHEDULED] = 'ehs_incidents_label_medicalDisposition_followupScheduled',
    _g[INCIDENT_MEDICAL_DISPOSITION.REFERRED_TO] = 'ehs_incidents_label_medicalDisposition_referredTo',
    _g);
export var INCIDENT_MEDICAL_STATUS_LANG_ID = (_h = {},
    _h[INCIDENT_MEDICAL_STATUS.NOT_STARTED] = 'ehs_incidents_label_incidentStatus_ready',
    _h[INCIDENT_MEDICAL_STATUS.CLAIM_FILED] = 'ehs_incidents_label_medicalStatus_claimFiled',
    _h[INCIDENT_MEDICAL_STATUS.CLOSED] = 'ehs_incidents_label_incidentStatus_closed',
    _h[INCIDENT_MEDICAL_STATUS.OPEN] = 'ehs_incidents_label_medicalStatus_open',
    _h[INCIDENT_MEDICAL_STATUS.PENDING_OUTSIDE_MEDICAL] = 'ehs_incidents_label_medicalStatus_pendingOutsideMedical',
    _h[INCIDENT_MEDICAL_STATUS.SUSPENDED] = 'ehs_incidents_label_medicalStatus_suspended',
    _h);
export var INCIDENT_CASE_CONDITION_LANG_ID = (_j = {},
    _j[INCIDENT_CASE_CONDITION.RECORDABLE] = 'ehs_incidents_label_caseCondition_recordable',
    _j[INCIDENT_CASE_CONDITION.WORK_RELATED] = 'ehs_incidents_label_caseCondition_workRelated',
    _j[INCIDENT_CASE_CONDITION.ALL_DAFW] = 'ehs_incidents_label_caseCondition_allDAFW',
    _j[INCIDENT_CASE_CONDITION.PENDING_DAFW_END_DATE] = 'ehs_incidents_label_caseCondition_pendingDAFWEndDate',
    _j[INCIDENT_CASE_CONDITION.ALL_RWA] = 'ehs_incidents_label_caseCondition_allRWA',
    _j[INCIDENT_CASE_CONDITION.PENDING_RWA_END_DATE] = 'ehs_incidents_label_caseCondition_pendingRWAEndDate',
    _j[INCIDENT_CASE_CONDITION.AMCARE_BYPASS] = 'ehs_incidents_label_amCareBypass',
    _j);
export var INCIDENT_PRINCIPAL_BODY_PART = {
    ANKLE: 'ankle',
    ARM: 'arm',
    BACK: 'back',
    FOOT: 'foot',
    HAND: 'hand',
    HEAD_FACE_EYE_EAR: 'head-face-eye-ear',
    HIP_PELVIS_BUTTOCKS: 'hip-pelvis-buttocks',
    KNEE: 'knee',
    LOWER_LEG: 'lower-leg',
    NECK: 'neck',
    SHOULDER: 'shoulder',
    THIGH: 'thigh',
    TRUNK: 'trunk',
    WHOLE_BODY: 'whole-body',
    WRIST: 'wrist'
};
export var INCIDENT_PRINCIPAL_BODY_PART_LANG_ID = (_k = {},
    _k[INCIDENT_PRINCIPAL_BODY_PART.ANKLE] = 'ehs_incidents_label_bodyPart_ankle',
    _k[INCIDENT_PRINCIPAL_BODY_PART.ARM] = 'ehs_incidents_label_bodyPart_arm',
    _k[INCIDENT_PRINCIPAL_BODY_PART.BACK] = 'ehs_incidents_label_bodyPart_back',
    _k[INCIDENT_PRINCIPAL_BODY_PART.FOOT] = 'ehs_incidents_label_bodyPart_foot',
    _k[INCIDENT_PRINCIPAL_BODY_PART.HAND] = 'ehs_incidents_label_bodyPart_hand',
    _k[INCIDENT_PRINCIPAL_BODY_PART.HEAD_FACE_EYE_EAR] = 'ehs_incidents_label_bodyPart_headFaceEyeEar',
    _k[INCIDENT_PRINCIPAL_BODY_PART.HIP_PELVIS_BUTTOCKS] = 'ehs_incidents_label_bodyPart_hipPelvisButtocks',
    _k[INCIDENT_PRINCIPAL_BODY_PART.KNEE] = 'ehs_incidents_label_bodyPart_knee',
    _k[INCIDENT_PRINCIPAL_BODY_PART.LOWER_LEG] = 'ehs_incidents_label_bodyPart_lowerLeg',
    _k[INCIDENT_PRINCIPAL_BODY_PART.NECK] = 'ehs_incidents_label_bodyPart_neck',
    _k[INCIDENT_PRINCIPAL_BODY_PART.SHOULDER] = 'ehs_incidents_label_bodyPart_shoulder',
    _k[INCIDENT_PRINCIPAL_BODY_PART.THIGH] = 'ehs_incidents_label_bodyPart_thigh',
    _k[INCIDENT_PRINCIPAL_BODY_PART.TRUNK] = 'ehs_incidents_label_bodyPart_trunk',
    _k[INCIDENT_PRINCIPAL_BODY_PART.WHOLE_BODY] = 'ehs_incidents_label_bodyPart_wholeBody',
    _k[INCIDENT_PRINCIPAL_BODY_PART.WRIST] = 'ehs_incidents_label_bodyPart_wrist',
    _k);
export var PHASE_STATUS_LANG_ID = (_l = {},
    _l[PHASE_STATUS.DRAFT] = 'ehs_incidents_label_phaseStatusDraft',
    _l[PHASE_STATUS.READY] = 'ehs_incidents_label_phaseStatusReady',
    _l[PHASE_STATUS.IN_PROGRESS] = 'ehs_incidents_label_phaseStatusInProgress',
    _l[PHASE_STATUS.IN_REVIEW] = 'ehs_incidents_label_statusForReview',
    _l[PHASE_STATUS.COMPLETED] = 'ehs_incidents_label_phaseStatusCompleted',
    _l);
export var phaseStatusLangId = __assign(__assign({}, PHASE_STATUS_LANG_ID), (_m = {}, _m[PHASE_STATUS.CONNECTED] = 'ehs_incidents_label_phaseStatusConnected', _m[PHASE_STATUS.NOT_CONNECTED] = 'ehs_incidents_label_phaseStatusNotConnected', _m));
export var FORM_STATUS_LANG_ID = (_o = {},
    _o[FORM_STATUS.IN_PROGRESS] = 'ehs_incidents_label_formStatusInProgress',
    _o[FORM_STATUS.COMPLETED] = 'ehs_incidents_label_formStatusCompleted',
    _o[FORM_STATUS.REVIEW] = 'ehs_incidents_label_formStatusReview',
    _o[FORM_STATUS.ACTIVE] = 'ehs_incidents_label_formStatusActive',
    _o);
export var COLORS = {
    GREEN: 'green',
    BLUE: 'blue',
    WHITE: 'white',
    ORANGE: 'orange'
};
var SEVERITY_LABELS = {
    A: 'Level A',
    B: 'Level B',
    C: 'Level C',
    D: 'Level D',
    PENDING: 'Pending'
};
var SEVERITY_LEVEL_LANG_ID = {
    SEVERITY_A: 'ehs_incidents_label_severityLevelN',
    SEVERITY_B: 'ehs_incidents_label_severityLevelN',
    SEVERITY_C: 'ehs_incidents_label_severityLevelN',
    SEVERITY_D: 'ehs_incidents_label_severityLevelN',
    SEVERITY_PENDING: 'ehs_incidents_label_pending'
};
// imported incident types
export var EXTERNAL_INCIDENT_TYPES = {
    ARC: 'ARC',
    GENSUITE: 'GENSUITE',
    EMT: 'EMT'
};
export var EXTERNAL_INCIDENT_LABELS = (_p = {},
    _p[EXTERNAL_INCIDENT_TYPES.ARC] = 'ARC',
    _p[EXTERNAL_INCIDENT_TYPES.GENSUITE] = 'Gensuite',
    _p);
export var INCIDENT_TYPES = {
    INJURY_ILLNESS: 'INJURY',
    EVENT: 'EVENT',
    EVENT_AND_INJURY: 'EVENT_AND_INJURY',
    NEAR_MISS: 'NEAR_MISS'
};
var INCIDENT_TYPES_LANG_ID = {
    INJURY: 'ehs_incidents_label_incidentType_injury',
    // Including this as list filter queries by IncidentTypes.key and key is INJURY_ILLNESS instead of INJURY
    INJURY_ILLNESS: 'ehs_incidents_label_incidentType_injury',
    EVENT: 'ehs_incidents_label_incidentType_event',
    EVENT_AND_INJURY: 'ehs_incidents_label_incidentType_event_and_injury',
    NEAR_MISS: 'ehs_incidents_label_incidentType_near_miss'
};
export var INCIDENT_INJURY_TYPES = {
    EMPLOYEE: 'Employee',
    CONTRACTOR: 'Contractor',
    VISITOR: 'Visitor',
    TRANSPORTER_PASSENGER: 'Transporter Passenger',
    COMMUNITY_MEMBER: 'Community Member',
    CUSTOMER: 'Customer',
    OTHER: 'Other'
};
export var INCIDENT_EVENT_TYPES = {
    AIR_OPERATIONS: 'Air operations',
    DRILL: 'Drill',
    ENVIRONMENTAL: 'ENVIRONMENTAL',
    FIRE: 'Fire',
    HIGH_RISK_OPERATIONS: 'HIGH_RISK_OPERATIONS',
    NATURAL_EVENT: 'Natural event',
    THREATENING_BEHAVIOR: 'Threatening behavior',
    TRANSPORTATION: 'Transportation'
};
export var INCIDENT_SUB_EVENT_TYPES = {
    LAST_MILE: 'Last Mile',
    MIDDLE_MILE: 'Middle Mile',
    TDR: 'TDR',
    YARD: 'Yard'
};
export var INCIDENT_STATUS_LIST = [
    INCIDENT_STATUS.CLOSED,
    INCIDENT_STATUS.DRAFT,
    INCIDENT_STATUS.IN_PROGRESS,
    INCIDENT_STATUS.FOR_REVIEW,
    INCIDENT_STATUS.READY
];
export var INCIDENT_EVENT_SUBTYPES = (_q = {},
    _q[INCIDENT_EVENT_TYPES.DRILL] = {
        ACTIVE_SHOOTER: 'Active shooter',
        BOMB_THREAT: 'Bomb threat',
        COMMUNICABLE_DISEASE_RESPONSE: 'Communicable disease response',
        FIRE: 'Fire (evacuation or fire suppression system tests)',
        NATURE_EVENT: 'Nature event (e.g. tornado, snow storm, etc.)',
        ENVIRONMENTAL_SPILL: 'Environmental spill'
    },
    _q[INCIDENT_EVENT_TYPES.ENVIRONMENTAL] = {
        SPILL_INSIDE: 'Spill or release - inside building',
        SPILL_OUTSIDE: 'Spill or release - outside building'
    },
    _q[INCIDENT_EVENT_TYPES.TRANSPORTATION] = {
        LAST_MILE: 'Last Mile',
        MIDDLE_MILE: 'Middle Mile',
        TDR: 'TDR',
        YARD: 'Yard'
    },
    _q[INCIDENT_EVENT_TYPES.HIGH_RISK_OPERATIONS] = {
        AUTOMATION: 'Automation',
        CONFINED_SPACES: 'Confined spaces',
        CONSTRUCTION: 'Construction, installation, demolition (CID)',
        CONVEYANCE: 'Conveyance',
        ELECTRICAL: 'Electrical',
        HOT_WORK: 'Hot work',
        LIFTING_OPERATIONS: 'Lifting operations',
        OTHER_POWER_EQUIPMENT: 'Other power equipment',
        PIT: 'PIT',
        STORED_ENERGY: 'Stored energy',
        WORK_AT_HEIGHT: 'Work at height',
        WORKING_IN_ISOLATION: 'Working in isolation'
    },
    _q[INCIDENT_EVENT_TYPES.THREATENING_BEHAVIOR] = {
        ASSAULT: 'Assault',
        GUN_THREAT_SHOOTING: 'Gun threat or shooting',
        VERBAL_HARRASSEMENT: 'Verbal harrassment'
    },
    _q);
export var SEARCH_FIELD_TYPES = {
    ANY: 'any',
    ALIAS: 'alias',
    CASE_NUMBER: FILTER_FIELDS.CASE_NUMBER,
    DESCRIPTION: 'description',
    EXTERNAL_ID: 'externalId',
    INCIDENT_ID: 'incidentId',
    INCIDENT_GROUP_ID: 'incidentGroupId'
};
var ALIAS = SEARCH_FIELD_TYPES.ALIAS, CASE_NUMBER = SEARCH_FIELD_TYPES.CASE_NUMBER, DESCRIPTION = SEARCH_FIELD_TYPES.DESCRIPTION, EXTERNAL_ID = SEARCH_FIELD_TYPES.EXTERNAL_ID, INCIDENT_ID = SEARCH_FIELD_TYPES.INCIDENT_ID, INCIDENT_GROUP_ID = SEARCH_FIELD_TYPES.INCIDENT_GROUP_ID;
var SEARCH_FIELD_LANG_IDS = (_r = {},
    _r[ALIAS] = 'ehs_incidents_label_searchBy_alias',
    _r[CASE_NUMBER] = 'ehs_incidents_label_searchBy_caseNumber',
    _r[DESCRIPTION] = 'ehs_incidents_label_searchBy_description',
    _r[EXTERNAL_ID] = 'ehs_incidents_label_searchBy_externalId',
    _r[INCIDENT_ID] = 'ehs_incidents_label_incidentId',
    _r[INCIDENT_GROUP_ID] = 'ehs_incidents_label_incidentGroupId',
    _r);
export var SEVERITY_LEVELS = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    PENDING: 'PENDING'
};
export var SEVERITY_TYPES = {
    ACTUAL: 'ACTUAL',
    POTENTIAL: 'POTENTIAL'
};
export var RISK_TYPES = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    SUBSTANTIAL: 'SUBSTANTIAL'
};
export var RISK_LABELS = (_s = {},
    _s[RISK_TYPES.LOW] = 'Low',
    _s[RISK_TYPES.MEDIUM] = 'Medium',
    _s[RISK_TYPES.SUBSTANTIAL] = 'Substantial',
    _s);
export var INJURY_ILLNESS_CATEGORIES = {
    EMPLOYEE: 'Employee',
    CONTRACTOR: 'Contractor',
    VISITOR: 'Visitor',
    // OTR
    TRANSPORTER_PASSENGER: 'Transporter Passenger',
    COMMUNITY_MEMBER: 'Community Member',
    CUSTOMER: 'Customer',
    OTHER: 'Other'
};
export var EVENT_TYPE_LANG_ID = {
    'Air operations': 'ehs_incidents_label_incidentType_air_operations',
    Drill: 'ehs_incidents_label_incidentType_drill',
    ENVIRONMENTAL: 'ehs_incidents_label_incidentType_environmental',
    Fire: 'ehs_incidents_label_incidentType_fire',
    HIGH_RISK_OPERATIONS: 'ehs_incidents_label_incidentType_high_risk_operations',
    'Natural event': 'ehs_incidents_label_incidentType_natural_event',
    'Threatening behavior': 'ehs_incidents_label_incidentType_threatening_behavior',
    Transportation: 'ehs_incidents_label_incidentType_transportation'
};
export var SUB_EVENT_TYPE_LANG_ID = {
    'Active shooter': 'ehs_incidents_label_incidentType_active_shooter',
    'Bomb threat': 'ehs_incidents_label_incidentType_bomb_threat',
    'Communicable disease response': 'ehs_incidents_label_incidentType_communicable_disease_response',
    'Confined spaces': 'ehs_incidents_label_incidentType_confined_spaces',
    'Construction, installation, demolition (CID)': 'ehs_incidents_label_incidentType_construction',
    'Fire (evacuation or fire suppression system tests)': 'ehs_incidents_label_incidentSubType_fire',
    'Gun threat or shooting': 'ehs_incidents_label_incidentType_gun_threat_shooting',
    'Hot work': 'ehs_incidents_label_incidentType_hot_work',
    'Lifting operations': 'ehs_incidents_label_incidentType_lifting_operations',
    'Nature event (e.g. tornado, snow storm, etc.)': 'ehs_incidents_label_incidentType_nature_event',
    'Other power equipment': 'ehs_incidents_label_incidentType_other_power_equipment',
    'Spill or release - inside building': 'ehs_incidents_label_incidentType_spill_inside',
    'Spill or release - outside building': 'ehs_incidents_label_incidentType_spill_outside',
    'Stored energy': 'ehs_incidents_label_incidentType_stored_energy',
    'Verbal harrassment': 'ehs_incidents_label_incidentType_verbal_harrassement',
    'Work at height': 'ehs_incidents_label_incidentType_work_at_height',
    'Working in isolation': 'ehs_incidents_label_incidentType_working_in_isolation',
    Assault: 'ehs_incidents_label_incidentType_assault',
    Automation: 'ehs_incidents_label_incidentType_automation',
    Conveyance: 'ehs_incidents_label_incidentType_conveyance',
    Electrical: 'ehs_incidents_label_incidentType_electrical',
    PIT: 'ehs_incidents_label_incidentType_pit',
    'Last Mile': 'ehs_incidents_label_incidentType_last_mile',
    'Middle Mile': 'ehs_incidents_label_incidentType_middle_mile',
    TDR: 'ehs_incidents_label_incidentType_tdr',
    Yard: 'ehs_incidents_label_incidentType_yard',
    'Environmental spill': 'ehs_incidents_label_incidentType_environmental_spill'
};
export var EMPLOYEE_TYPE_LANG_ID = {
    Employee: 'ehs_incidents_label_incidentType_employee',
    Contractor: 'ehs_incidents_label_incidentType_contractor',
    Visitor: 'ehs_incidents_label_incidentType_visitor',
    'Transporter Passenger': 'ehs_incidents_label_incidentType_transporter_passenger',
    'Community Member': 'ehs_incidents_label_incidentType_community_member',
    Customer: 'ehs_incidents_label_incidentType_customer',
    Other: 'ehs_incidents_label_incidentType_other'
};
var PROPERTY_DAMAGE_CATEGORIES = {};
export var EVENT_CATEGORIES = {
    ENVIRONMENTAL: 'ENVIRONMENTAL',
    HIGH_RISK_OPERATIONS: 'HIGH_RISK_OPERATIONS',
    PIT: 'PIT',
    TRANSPORTATION: 'TRANSPORTATION'
};
export var INCIDENT_CATEGORIES = __assign(__assign(__assign({ DEFAULT: 'DEFAULT' }, INJURY_ILLNESS_CATEGORIES), PROPERTY_DAMAGE_CATEGORIES), EVENT_CATEGORIES);
export var ENTITY_TYPE = {
    RISK_ASSESSMENT_GROUP: 'RISK_ASSESSMENT_GROUP',
    RISK_ASSESSMENT_CATEGORY: 'RISK_ASSESSMENT_CATEGORY',
    RISK_ASSESSMENT_HAZARD: 'RISK_ASSESSMENT_HAZARD',
    RISK_ASSESSMENT_SEVERITY: 'RISK_ASSESSMENT_SEVERITY',
    RISK_ASSESSMENT_LIKELINESS: 'RISK_ASSESSMENT_LIKELINESS',
    SITE_CONFIG_LOCATION: 'SITE_CONFIG_LOCATION',
    SITE_CONFIG_PROCESS_PATH: 'SITE_CONFIG_PROCESS_PATH'
};
export var RISK_ASSESSMENT_ENTITY_TYPES = [
    ENTITY_TYPE.RISK_ASSESSMENT_GROUP,
    ENTITY_TYPE.RISK_ASSESSMENT_CATEGORY,
    ENTITY_TYPE.RISK_ASSESSMENT_HAZARD
];
// Setting this based on MAX_CRITICALITY_WITHOUT_VERIFICATION setting in @amzn/austin-core risk-assessments calculation
// Any criticality at SUBSTANTIAL, HIGH, and CRITICAL ranges should require verification
// https://code.amazon.com/packages/AustinCore/blobs/a08606af9cd913632a4b330f540f323172c24150/--/packages/austin-core/src/actions/modules/risk-assessment.ts#L263
export var MIN_RISK_CRITICALITY = (_u = (_t = RISK_ASSESSMENT_CONFIG()) === null || _t === void 0 ? void 0 : _t.get(RISK_CRITICALITY.HIGH)) === null || _u === void 0 ? void 0 : _u.minRange;
export var INITIAL_RISK_ASSESSEMENT_TYPE = 'INITIAL';
/**
 * @todo these should be replaced with intl.formatMessage, and localization ids, i.e ehs_incidents_label_type_Environmental
 * instead of using the formatLabel function, for UI displaying of labels
 * this will likely need to be done inside a hook, since useIntl can only be used in a component or hook
 */
export var INCIDENT_CATEGORY_LABELS = __assign(__assign(__assign(__assign(__assign({ 
    // incident types
    INJURY: 'Injury or illness', INJURY_ILLNESS: 'Injury or illness', PROPERTY_DAMAGE: 'Property damage', EVENT: 'Event', NEAR_MISS: 'Near miss', 
    // injury/employee types
    AMAZON_FULLTIME: 'Employee', EMPLOYEE: 'Employee', CONTRACTOR: 'Contractor', VISITOR: 'Visitor', TRANSPORTER_PASSENGER: 'Transporter Passenger', COMMUNITY_MEMBER: 'Community Member', CUSTOMER: 'Customer', OTHER: 'Other', 
    // Event types
    AIR_OPERATIONS: 'Air operations', ENVIRONMENTAL: 'Environmental', HIGH_RISK_OPERATIONS: 'High risk operations', DRILL: 'Drill', FIRE: 'Fire', NATURAL_EVENT: 'Natural event', THREATENING_BEHAVIOR: 'Threatening behavior', TRANSPORTATION: 'Transportation', PIT: 'PIT', MACHINERY: 'Machinery' }, get(INCIDENT_EVENT_SUBTYPES, INCIDENT_EVENT_TYPES.DRILL)), get(INCIDENT_EVENT_SUBTYPES, INCIDENT_EVENT_TYPES.ENVIRONMENTAL)), get(INCIDENT_EVENT_SUBTYPES, INCIDENT_EVENT_TYPES.TRANSPORTATION)), get(INCIDENT_EVENT_SUBTYPES, INCIDENT_EVENT_TYPES.HIGH_RISK_OPERATIONS)), get(INCIDENT_EVENT_SUBTYPES, INCIDENT_EVENT_TYPES.THREATENING_BEHAVIOR));
export var INCIDENT_CATEGORY_LANG_ID = Object.keys(INCIDENT_CATEGORY_LABELS).reduce(function (langIds, key) {
    var _a;
    return (__assign(__assign({}, langIds), (_a = {}, _a[key] = "ehs_incidents_label_incidentType_".concat(key.toLowerCase()), _a)));
}, {});
export var LABELS = __assign(__assign(__assign(__assign({}, EXTERNAL_INCIDENT_LABELS), INCIDENT_CATEGORY_LABELS), RISK_LABELS), SEVERITY_LABELS);
export var LANGUAGE_IDS = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, FILTER_NAME_LANG_IDS), FORM_STATUS_LANG_ID), INCIDENT_CASE_CONDITION_LANG_ID), INCIDENT_CATEGORY_LANG_ID), INCIDENT_MEDICAL_DISPOSITION_LANG_ID), INCIDENT_MEDICAL_STATUS_LANG_ID), INCIDENT_PRINCIPAL_BODY_PART_LANG_ID), INCIDENT_STATUS_LANG_ID), ISSUE_CRITICALITY_KEY_MAP), PHASE_TYPE_LANG_ID), PHASE_SECTION_TYPE_LANG_ID), PHASE_STATUS_LANG_ID), ATTACHMENT_STATUS_LANG_ID), SEARCH_FIELD_LANG_IDS), SEVERITY_LEVEL_LANG_ID), INCIDENT_TYPES_LANG_ID), PARK_STATUS_LANG_ID), { day: 'ehs_incidents_label_day', DELETED: 'ehs_incidents_label_deleted', other: 'ehs_incidents_label_other', PENDING: 'ehs_incidents_label_pending', night: 'ehs_incidents_label_night', UPLOADED: 'ehs_incidents_label_uploaded' });
export function formatLabelInLangId(id) {
    return get(LANGUAGE_IDS, id, id);
}
export function formatLabel(id) {
    return get(LABELS, id, id);
}
export function formatTitle(_a) {
    var _b, _c;
    var _d = _a.attributes, attributes = _d === void 0 ? [] : _d, incidentCategory = _a.category, caseNumber = _a.caseNumber, incidentType = _a.incidentType, prefix = _a.prefix, _e = _a.separator, separator = _e === void 0 ? ' - ' : _e, incidentSubCategory = _a.subCategory, suffix = _a.suffix;
    var title = [];
    if (prefix) {
        title.push(prefix);
    }
    var incidentTypeLabel;
    if (incidentType) {
        var labels = [];
        /**
         * Incident Category/Subcategory is currently not stored on the Incident, but on customSearchAttributes
         * Default to passed Category/Subcategory but check for values on attributes if undefined
         */
        var category = incidentCategory !== null && incidentCategory !== void 0 ? incidentCategory : (_b = attributes.find(function (_a) {
            var key = _a.key;
            return key === 'employeeType' || key === 'eventType';
        })) === null || _b === void 0 ? void 0 : _b.value;
        var subCategory = incidentSubCategory !== null && incidentSubCategory !== void 0 ? incidentSubCategory : (_c = attributes.find(function (_a) {
            var key = _a.key;
            return key === 'subEventType';
        })) === null || _c === void 0 ? void 0 : _c.value;
        // Event types that have Category/Sub category should only show Category/Sub Category
        // else include the Incident type
        if (incidentType !== INCIDENT_TYPES.EVENT ||
            !category ||
            !subCategory) {
            labels.push(formatLabel(incidentType));
        }
        if (category) {
            labels.push(formatLabel(category));
        }
        if (subCategory) {
            labels.push(formatLabel(subCategory));
        }
        /**
         * @todo map Incident types / categories to localization id
         * https://sim.amazon.com/issues/INCIDENTS-1217
         */
        incidentTypeLabel = labels.join(', ');
    }
    if (incidentTypeLabel) {
        title.push(incidentTypeLabel);
    }
    if (caseNumber) {
        title.push("#".concat(caseNumber));
    }
    if (suffix) {
        title.push(suffix);
    }
    return title.join(separator);
}
export var DEFAULT_MESSAGE_AUTO_HIDE_DURATION = 5000;
// Create the Summary nav item here so it can be combined with
// other Summary phases from getPhaseSections
export var SUMMARY_PHASE = {
    name: 'Summary',
    section: PHASE_SECTION_TYPE.SUMMARY,
    type: PHASE_TYPE.SUMMARY
};
export var MANAGE_INCIDENT_PHASE = {
    name: 'Initial information',
    section: PHASE_SECTION_TYPE.SUMMARY,
    type: PHASE_TYPE.MANAGE_INCIDENT
};
export var STAGE_STATUS_ICON = {
    IN_PROGRESS: STATUS_INDICATOR.IN_PROGRESS,
    COMPLETED: STATUS_INDICATOR.COMPLETED,
    IN_REVIEW: STATUS_INDICATOR.VERIFY,
    READY: STATUS_INDICATOR.READY
};
