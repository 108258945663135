var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* istanbul ignore file */
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RESOURCE_NAMESPACE, routeConfig } from '@amzn/austin-core';
var IncidentsDashboard = lazy(function () { return import('./IncidentsDashboard'); });
var IncidentRecord = lazy(function () { return import('./record/IncidentRecord'); });
var InitialReport = lazy(function () {
    return import('./initial-complaint/InitialComplaint');
});
var InitialReportLogin = lazy(function () {
    return import('./initial-complaint/InitialReportLogin');
});
var Osha = lazy(function () { return import('./reporting/osha/Osha'); });
var MedicalRecordExport = lazy(function () { return import('./reporting/medical-record-export/MedicalRecordExport'); });
var IncidentGroup = lazy(function () { return import('./multi-person-incident/IncidentGroup'); });
var CreateIncident = lazy(function () { return import('./components/MultiPersonIncident/create/CreateIncident').then(function (module) { return ({ default: module.CreateIncident }); }); });
var Routes = function () {
    var getPath = routeConfig(RESOURCE_NAMESPACE.INCIDENTS).getRawPath;
    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsxs(Switch, __assign({ "data-testid": "IncidentsRouter" }, { children: [_jsx(Route, { exact: true, path: getPath('default'), render: function () { return _jsx(Redirect, { to: getPath('list.incidents') }); } }), _jsx(Route, { exact: true, path: getPath('incident.group'), component: IncidentGroup }), _jsx(Route, { exact: true, path: getPath('incident.group.phase'), component: IncidentGroup }), _jsx(Route, { exact: true, component: IncidentsDashboard, path: getPath('list.incidents') }), _jsx(Route, { exact: true, path: getPath('incident.create'), component: CreateIncident }), _jsx(Route, { exact: true, component: IncidentRecord, path: getPath('incident.phase') }), _jsx(Route, { exact: true, component: InitialReport, path: getPath('incident.irf') }), _jsx(Route, { exact: true, component: InitialReportLogin, path: getPath('incident.irf.login') }), _jsx(Route, { exact: true, component: InitialReport, path: getPath('incident.irf.report') }), _jsx(Route, { exact: true, component: Osha, path: getPath('incident.reporting.osha') }), _jsx(Route, { exact: true, component: MedicalRecordExport, path: getPath('incident.reporting.medical-record-export') })] })) })));
};
export default Routes;
